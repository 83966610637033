.contentlayer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contentlayer h1 {
    font-size: 32px;
    font-weight: 600;
}

.contentlayer h2 {
    font-size: 28px;
    font-weight: 600;
}

.contentlayer h3 {
    font-size: 24px;
    font-weight: 600;
}

.contentlayer ul,
.contentlayer ol {
    list-style-type: disc;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.contentlayer li > ul,
.contentlayer li > ol {
    margin-top: 12px;
}

